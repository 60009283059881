import { Image } from 'react-konva'
import React from 'react'

interface ICameraFeedImageProps {
  image: any
  width: number
  height: number
}

const CameraFeedImage: React.FC<ICameraFeedImageProps> = ({
  image,
  width,
  height
}) => {
  return <Image image={image} width={width} height={height} />
}

export default CameraFeedImage
