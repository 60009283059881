import {
  EEventTriggerType,
  EEventTriggerUtil,
  IEventTrigger
} from './eventTrigger'

export class AnprEventTrigger implements IEventTrigger {
  enabled: boolean
  anprStorageTime: number
  localId: string = 'anpr'
  objectType: EEventTriggerType

  get typeShort(): string {
    return EEventTriggerUtil.toString(this.objectType)
  }

  constructor(props) {
    this.objectType = EEventTriggerType.anpr
    this.enabled = props.enabled
    this.anprStorageTime = props.anprStorageTime
  }

  public static default(): AnprEventTrigger {
    return new AnprEventTrigger({
      localId: 'anpr',
      enabled: false
    })
  }

  //not unused
  getRequestObject() {
    let object: any = Object.assign({}, this)

    delete object.objectType
    delete object.isSelected
    delete object.hasChanged
    delete object.coordinates
    delete object.name
    delete object.id
    delete object.localId

    return object
  }
}
